import { styled, TextField } from '@mui/material'
import CustomIcon, { Icons } from 'packs/main/components/CustomIcon/CustomIcon'
import LanguageIcon from 'packs/main/Monaco/FilePane/LanguageIcon'
import { ScrollView } from 'packs/main/ScrollView/ScrollView'
import React, { FC, useCallback, useEffect, useMemo, useRef, useState } from 'react'
import useResizeObserver from 'use-resize-observer/polyfilled'

import { useLanguages } from '../../../../../graphql/hooks/languages/useLanguages'
import { useProjectTemplates } from '../../../../../graphql/hooks/useProjectTemplates/useProjectTemplates'
import { Language, ProjectTemplate } from '../../../../../graphql/types'
import { EnvironmentTypes } from '../../../Environments/EnvironmentsContext/EnvironmentsContext'
import { CATEGORIES_SUPPORTING_DISABLED_EXECUTION } from '../LanguageEnvironments/LanguageEnvironments'
import { useHasSpreadsheets } from '../LanguageEnvironments/useHasSpreadsheets'

const SEARCH_RESULTS_MAX_HEIGHT = 440

const SearchWrapper = styled('div')(({ theme }) => ({
  position: 'relative',
  width: '100%',
  height: 42,
  marginBottom: theme.spacing(4.5),
}))

const SearchInputWrapper = styled('div')(({ theme }) => ({
  maxWidth: 500,
  width: '100%',
  position: 'absolute',
  height: 42,
  background: theme.palette.environmentSelector.searchField.background,
  borderRadius: 4,
  zIndex: 10,
  overflow: 'hidden',
  transition: 'height 0.15s ease-in-out',
  '&:focus-within': {
    outline: `2px solid ${theme.palette.environmentSelector.searchField.outline}`,
  },
}))

const SearchInput = styled(TextField)(({ theme }) => ({
  width: '100%',
  border: 'none',
  '& .MuiOutlinedInput-root': {
    background: 'transparent',
    borderRadius: 0,
    height: '100%',
    '& fieldset': {
      border: 'none',
    },
  },
}))

const SearchDropdown = styled(ScrollView)(({ theme }) => ({
  maxHeight: SEARCH_RESULTS_MAX_HEIGHT,
  width: '100%',
  overflow: 'auto',
}))

const SearchResult = styled('div')<{ focused: boolean }>(({ theme, focused }) => ({
  boxSizing: 'border-box',
  width: '100%',
  padding: `${theme.spacing(2)} ${theme.spacing(3)}`,
  display: 'flex',
  alignItems: 'center',
  cursor: 'pointer',
  fontSize: 14,
  svg: {
    marginRight: theme.spacing(2),
  },
  backgroundColor: focused ? theme.palette.environmentSelector.searchField.hover : 'transparent',
}))

interface SearchFieldProps {
  execEnabled: boolean
  onSelect: (type: EnvironmentTypes, language: Language | ProjectTemplate) => void
}

export const SearchField: FC<React.PropsWithChildren<SearchFieldProps>> = ({
  execEnabled,
  onSelect,
}) => {
  const [inputFocused, setInputFocused] = useState<boolean>(false)
  const [searchText, setSearchText] = useState<string>('')
  const inputRef = useRef<HTMLDivElement | null>(null)
  const scrollViewRef = useRef<HTMLDivElement | null>(null)
  const { ref, height } = useResizeObserver<HTMLDivElement>({
    box: 'border-box',
  })

  const resultsHeight =
    inputFocused && height ? Math.min(SEARCH_RESULTS_MAX_HEIGHT, height) + 42 : 38

  const { languages: classicLanguages } = useLanguages()
  const { projectTemplates } = useProjectTemplates()
  const hasSpreadsheets = useHasSpreadsheets()

  const [focusedResult, setFocusedResult] = useState<number>(0)

  const onChangeLanguage = useCallback(
    (lang: Language | ProjectTemplate) => {
      const type = projectTemplates.some((template) => template.id === lang.id)
        ? EnvironmentTypes.Project
        : EnvironmentTypes.Language
      onSelect(type, lang)
    },
    [onSelect, projectTemplates]
  )

  const languages = useMemo(() => {
    const all = [...projectTemplates, ...classicLanguages]
    // Wee hackfix to prevent both the project template HTML and classic HTML from showing up.
    const htmlTemplateIdx = all.findIndex((lang) => (lang as ProjectTemplate).slug === 'html')
    const classicHtmlIdx = all.findIndex((lang) => lang.id === 'html')
    if (htmlTemplateIdx !== -1 && classicHtmlIdx !== -1) {
      all.splice(classicHtmlIdx, 1)
    }

    return (
      all
        // filter out the gsheets language if the pad owner or current user shouldn't be able
        // to access spreadsheets yet
        .filter((lang) => hasSpreadsheets || lang.category !== 'spreadsheets')
        // filter out languages that don't support disabled execution if exec is currently disabled
        .filter(
          (lang) => execEnabled || CATEGORIES_SUPPORTING_DISABLED_EXECUTION.includes(lang.category)
        )
    )
  }, [projectTemplates, classicLanguages, hasSpreadsheets, execEnabled])

  // These matches should be sorted alphabetically but also sort by the index of the first match
  // matches closer to the start of the string should be higher up in the list
  const matchedLanguages = useMemo(
    () =>
      languages
        .filter((lang) => {
          const langParts = lang.name.split(/(?<=[a-z])(?=[A-Z])|[\s-]/).filter(Boolean)
          const isLangMatch =
            langParts.some((word) => word.toLowerCase().startsWith(searchText.toLowerCase())) ||
            langParts.join('').toLowerCase().startsWith(searchText.toLowerCase())

          const isCategoryMatch =
            (lang as ProjectTemplate).category &&
            (lang as ProjectTemplate).category.startsWith(searchText.toLowerCase())

          return isLangMatch || isCategoryMatch
        })
        .sort((a, b) => {
          // exact matches should always come first
          if (a.name.toLowerCase() === searchText.toLowerCase()) return -1
          if (b.name.toLowerCase() === searchText.toLowerCase()) return 1

          // if it's not an exact match, we should sort by the index of the first match
          // matches closer to the start of the string should be higher up in the list
          // i.e. searching for "c" should return "C++" before "Objective-C"
          const parts = searchText.toLowerCase().split(' ')
          const aIndex = parts.findIndex((part) => a.name.toLowerCase().startsWith(part))
          const bIndex = parts.findIndex((part) => b.name.toLowerCase().startsWith(part))
          return bIndex - aIndex
        }),
    [languages, searchText]
  )

  const hasResults = matchedLanguages.length > 0

  const handleKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLDivElement>) => {
      switch (e.key) {
        case 'ArrowDown':
          e.preventDefault()
          setFocusedResult((prev) => Math.min(prev + 1, matchedLanguages.length - 1) ?? 0)
          break
        case 'ArrowUp':
          e.preventDefault()
          setFocusedResult((prev) => Math.max(prev - 1, 0) ?? 0)
          break
        case 'Enter':
          e.preventDefault()
          onChangeLanguage(matchedLanguages[focusedResult])
          break
        case 'Escape':
          e.preventDefault()
          setInputFocused(false)
          break
        default:
          setInputFocused(true)
          break
      }
    },
    [focusedResult, matchedLanguages, onChangeLanguage]
  )

  /**
   * Ensure that the selected result is always visible in the scroll view
   */
  useEffect(() => {
    if (scrollViewRef.current && hasResults) {
      const selectedResult = scrollViewRef.current.querySelector(
        `#search-result-${focusedResult}`
      ) as HTMLDivElement
      selectedResult.scrollIntoView({
        // @ts-ignore - this is a valid option but not in the types, it's a new browser feature
        // browsers that don't support behavior will have no scroll animation, but will still
        // scroll to the element
        behavior: 'instant',
        block: 'nearest',
        inline: 'start',
      })
    }
  }, [focusedResult, hasResults])

  return (
    <SearchWrapper>
      <SearchInputWrapper
        style={{
          height: searchText ? resultsHeight : 'auto',
        }}
      >
        <SearchInput
          size="small"
          variant="outlined"
          placeholder="Search..."
          ref={inputRef}
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value)
            setFocusedResult(0)
          }}
          onKeyDown={handleKeyDown}
          autoFocus
          onFocus={() => setInputFocused(true)}
          onBlur={() => setInputFocused(false)}
          autoComplete="off"
          InputProps={{
            startAdornment: (
              <CustomIcon icon={Icons.Search} width={16} color="inherit" sx={{ marginRight: 1 }} />
            ),
          }}
        />
        {!!searchText && (
          <SearchDropdown ref={scrollViewRef} data-testid="search-dropdown">
            <div ref={ref}>
              {hasResults ? (
                matchedLanguages.map((lang, i) => (
                  <SearchResult
                    key={lang.name}
                    onMouseDown={() => {
                      setSearchText('')
                      onChangeLanguage(lang)
                    }}
                    onMouseOver={() => setFocusedResult(matchedLanguages.indexOf(lang))}
                    focused={matchedLanguages.indexOf(lang) === focusedResult}
                    id={`search-result-${i}`}
                    aria-selected={matchedLanguages.indexOf(lang) === focusedResult}
                  >
                    <LanguageIcon
                      language={
                        typeof lang.id === 'number' ? (lang as ProjectTemplate).slug : lang.id
                      }
                      width={20}
                    />{' '}
                    {lang.name}
                  </SearchResult>
                ))
              ) : (
                <SearchResult focused={false}>No matching languages found</SearchResult>
              )}
            </div>
          </SearchDropdown>
        )}
      </SearchInputWrapper>
    </SearchWrapper>
  )
}
