import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import { Box, Collapse, Link } from '@mui/material'
import { Alert } from '@mui/material'
import makeStyles from '@mui/styles/makeStyles'
import * as Sentry from '@sentry/browser'
import React, { useState } from 'react'

interface IGenericErrorViewProps {
  /** Optional message to override the default error message. */
  message?: React.ReactNode
  /** Optional Error to add some extra info the message. */
  error?: Error
  /** Optional flag to include support info. Defaulted to true. */
  includeSupportLinks?: boolean
}

const useStyles = makeStyles({
  moreInfoBtn: {
    display: 'flex',
    alignItems: 'center',
    cursor: 'pointer',
    textDecoration: 'underline',
  },
  moreInfoText: {
    fontSize: '0.75rem',
  },
})

/**
 * GenericErrorView shows the user a view with an error Alert. A default erorr with links to support and the status
 * page is provided if no message is specified. If an Error is provided, a button is shown that will expand an
 * area below the message to show the Error's message and the Sentry event it associated with the Error.
 */
export const GenericErrorView: React.FC<React.PropsWithChildren<IGenericErrorViewProps>> = ({
  message,
  error,
  includeSupportLinks = true,
}) => {
  const styles = useStyles()
  const [showMoreInfo, setShowMoreInfo] = useState(false)

  return (
    <Box
      minHeight={200}
      display="flex"
      justifyContent="center"
      alignItems="center"
      data-testid="generic-error-view"
    >
      <Alert severity="error">
        {message ||
          'An error occurred while loading this content. Please try refreshing the page or opening in an incognito window.'}
        {includeSupportLinks && (
          <>
            <br />
            <br />
            If this continues to happen, please contact{' '}
            <Link
              href="mailto:support@coderpad.io?subject=Error%20in%20CoderPad"
              sx={{ color: (theme) => theme.palette.tokens.text.link }}
              underline="hover"
            >
              support@coderpad.io
            </Link>{' '}
            or check our{' '}
            <Link
              href="https://status.coderpad.io"
              target="_blank"
              sx={{ color: (theme) => theme.palette.tokens.text.link }}
              underline="hover"
            >
              status page
            </Link>
            .
          </>
        )}
        {error ? (
          <Box mt={3}>
            <Link
              classes={{ root: styles.moreInfoBtn }}
              onClick={() => setShowMoreInfo(!showMoreInfo)}
              color="inherit"
              underline="hover"
            >
              More Info {showMoreInfo ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
            </Link>
            <Collapse in={showMoreInfo}>
              <pre className={styles.moreInfoText}>
                Message: {error.message}
                <br />
                {/* This is the official way Sentry provides to get an event id */}
                Event ID: {Sentry.lastEventId()}
              </pre>
            </Collapse>
          </Box>
        ) : null}
      </Alert>
    </Box>
  )
}
