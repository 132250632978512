import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const VB: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const svgId = props.id ?? 'vb'

  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <g clipPath={`url(#${svgId}clip0_1574_1757)`}>
        <path
          d="M2.60063 14.1629C2.49516 14.2535 2.36678 14.3129 2.22974 14.3345C2.0927 14.3561 1.95238 14.3391 1.82437 14.2854L0.46125 13.7106C0.32528 13.6536 0.209027 13.5576 0.126998 13.4346C0.0449691 13.3116 0.0007989 13.167 0 13.0189L0 4.97916C0.0007989 4.83109 0.0449691 4.68652 0.126998 4.56352C0.209027 4.44052 0.32528 4.34452 0.46125 4.28751L1.82437 3.72212C1.95238 3.66839 2.0927 3.65139 2.22974 3.67302C2.36678 3.69464 2.49516 3.75405 2.60063 3.84462L2.90625 4.09905C2.84427 4.05426 2.77119 4.02756 2.69507 4.02187C2.61895 4.01619 2.54274 4.03175 2.47486 4.06684C2.40698 4.10193 2.35006 4.15518 2.31038 4.22073C2.2707 4.28627 2.24981 4.36156 2.25 4.43828V13.5598C2.24981 13.6366 2.2707 13.7118 2.31038 13.7774C2.35006 13.8429 2.40698 13.8962 2.47486 13.9313C2.54274 13.9664 2.61895 13.9819 2.69507 13.9762C2.77119 13.9706 2.84427 13.9438 2.90625 13.8991L2.60063 14.1629Z"
          fill="#52218A"
        />
        <path
          d="M0.46125 13.7107C0.32528 13.6537 0.209027 13.5577 0.126998 13.4347C0.0449691 13.3117 0.0007989 13.1671 0 13.0191L0 12.9569C0.000924223 13.0439 0.0277705 13.1286 0.0770744 13.2001C0.126378 13.2717 0.195877 13.3267 0.276603 13.3582C0.357329 13.3896 0.445577 13.396 0.529959 13.3765C0.614341 13.3571 0.690985 13.3126 0.75 13.249L12.375 0.329911C12.5396 0.165259 12.7509 0.055855 12.9799 0.0167129C13.2088 -0.0224292 13.4442 0.0106096 13.6537 0.111295L17.3625 1.90545C17.5535 1.99778 17.7147 2.14246 17.8275 2.32285C17.9403 2.50324 18.0001 2.71198 18 2.92503V2.96838C17.9999 2.83322 17.9616 2.70087 17.8896 2.5867C17.8177 2.47252 17.7149 2.38122 17.5934 2.3234C17.4719 2.26557 17.3365 2.24359 17.203 2.26002C17.0695 2.27644 16.9434 2.3306 16.8394 2.41618L2.90625 13.8992L2.60063 14.163C2.49516 14.2536 2.36678 14.313 2.22974 14.3346C2.0927 14.3563 1.95238 14.3393 1.82437 14.2855L0.46125 13.7107Z"
          fill="#6C33AF"
        />
        <path
          d="M0.46125 4.28751C0.32528 4.34452 0.209027 4.44052 0.126998 4.56352C0.0449691 4.68652 0.0007989 4.83109 0 4.97917L0 5.04136C0.000924223 4.95434 0.0277705 4.86961 0.0770744 4.79808C0.126378 4.72655 0.195877 4.67151 0.276603 4.64007C0.357329 4.60863 0.445577 4.60222 0.529959 4.62168C0.614341 4.64114 0.690985 4.68557 0.75 4.74924L12.375 17.6683C12.5396 17.833 12.7509 17.9424 12.9799 17.9815C13.2088 18.0206 13.4442 17.9876 13.6537 17.8869L17.3625 16.0928C17.5535 16.0004 17.7147 15.8558 17.8275 15.6754C17.9403 15.495 18.0001 15.2862 18 15.0732V15.0298C17.9999 15.165 17.9616 15.2974 17.8896 15.4115C17.8177 15.5257 17.7149 15.617 17.5934 15.6748C17.4719 15.7326 17.3365 15.7546 17.203 15.7382C17.0695 15.7218 16.9434 15.6676 16.8394 15.582L2.90625 4.09905L2.60063 3.8352C2.49422 3.74618 2.36539 3.6885 2.22838 3.66854C2.09137 3.64858 1.95154 3.66713 1.82437 3.72212L0.46125 4.28751Z"
          fill="#854CC7"
        />
        <path
          d="M13.6537 17.8869C13.4442 17.9876 13.2088 18.0207 12.9799 17.9815C12.7509 17.9424 12.5396 17.833 12.375 17.6683C12.4674 17.7605 12.5849 17.8232 12.7126 17.8485C12.8404 17.8737 12.9727 17.8604 13.093 17.8103C13.2132 17.7601 13.3161 17.6753 13.3885 17.5665C13.4609 17.4578 13.4997 17.3299 13.5 17.199V0.802851C13.5004 0.671657 13.4622 0.543276 13.39 0.43394C13.3179 0.324604 13.2151 0.239223 13.0947 0.188592C12.9743 0.13796 12.8417 0.124353 12.7135 0.149489C12.5854 0.174626 12.4676 0.237378 12.375 0.329811C12.5396 0.16516 12.7509 0.0557558 12.9799 0.0166137C13.2088 -0.0225284 13.4442 0.0105104 13.6537 0.111195L17.3625 1.90347C17.5535 1.99579 17.7147 2.14048 17.8275 2.32087C17.9403 2.50125 18.0001 2.71 18 2.92305V15.0751C18.0001 15.2881 17.9403 15.4969 17.8275 15.6773C17.7147 15.8576 17.5535 16.0023 17.3625 16.0947L13.6537 17.8869Z"
          fill="#B179F1"
        />
        <g opacity="0.25">
          <path
            opacity="0.25"
            fillRule="evenodd"
            clipRule="evenodd"
            d="M12.9169 17.9697C13.1648 18.0265 13.4245 17.9973 13.6537 17.8867L17.3625 16.0945C17.5535 16.0022 17.7147 15.8575 17.8275 15.6771C17.9403 15.4967 18.0001 15.2879 18 15.0749V2.92287C18.0001 2.70982 17.9403 2.50108 17.8275 2.32069C17.7147 2.14031 17.5535 1.99562 17.3625 1.90329L13.6537 0.111021C13.3913 -0.0157666 13.0902 -0.0353574 12.8137 0.0563675C12.6484 0.112048 12.4982 0.2056 12.375 0.329637L6.3975 6.9748L2.90625 4.09888L2.60063 3.83503C2.50871 3.75604 2.39915 3.70055 2.28129 3.67332C2.16343 3.64608 2.04076 3.64789 1.92375 3.67861C1.88949 3.68621 1.85616 3.69759 1.82437 3.71253L0.46125 4.28734C0.333666 4.34029 0.223095 4.4276 0.141712 4.53966C0.0603295 4.65172 0.011292 4.78418 0 4.92245L0 13.0753C0.011292 13.2136 0.0603295 13.3461 0.141712 13.4581C0.223095 13.5702 0.333666 13.6575 0.46125 13.7104L1.82437 14.2758C1.85616 14.2908 1.88949 14.3021 1.92375 14.3097C2.04076 14.3405 2.16343 14.3423 2.28129 14.315C2.39915 14.2878 2.50871 14.2323 2.60063 14.1533L2.90625 13.8989L6.3975 11.023L12.375 17.6681C12.524 17.8176 12.7117 17.9221 12.9169 17.9697ZM13.5 5.16934L8.85188 8.99888L13.5 12.8284V5.16934ZM2.25 6.4113L4.57687 8.99888L2.25 11.5865V6.4113Z"
            fill={`url(#${svgId}paint0_linear_1574_1757)`}
          />
        </g>
      </g>
      <defs>
        <linearGradient
          id={`${svgId}_paint0_linear_1574_1757`}
          x1="9"
          y1="-0.00017109"
          x2="9"
          y2="17.9979"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="white" />
          <stop offset="1" stopColor="white" stopOpacity="0" />
        </linearGradient>
        <clipPath id={`${svgId}_clip0_1574_1757`}>
          <rect width="18" height="18" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}
