import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const Elixir: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const svgId = props.id ?? 'elixir'

  return (
    <SvgIcon {...props} viewBox="0 0 18 24">
      <g clipPath={`url(#${svgId}_clip0_1574_1890)`}>
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0214 0.0925179C4.89599 2.57196 -1.54316 15.4317 3.8228 21.0752C8.78168 27.2739 19.6246 21.3158 16.479 13.5814C12.5008 6.92023 10.0029 6.56867 10.0214 0.0925179Z"
          fill={`url(#${svgId}_paint0_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.0214 0.0370064C4.91449 2.51645 -1.54315 15.3392 3.8413 21.0012C5.82116 23.2956 9.02222 24.0543 10.817 22.685C15.7019 19.2064 12.1863 12.8598 12.7414 8.15995C10.706 6.27262 9.9844 2.09087 10.0214 0.0370064Z"
          fill={`url(#${svgId}_paint1_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M8.59659 0.795643C4.47036 3.73767 3.37866 14.6731 4.1373 18.2257C5.65457 27.1258 18.0518 23.4622 17.0156 15.1727C15.9054 9.76974 7.98598 7.91941 8.59659 0.795643Z"
          fill={`url(#${svgId}_paint2_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M12.6859 9.21464C14.6102 11.6941 15.0358 13.3964 13.9441 14.3771C8.81872 17.9667 2.60161 15.7463 3.95235 7.47533C2.19454 11.6571 -1.06204 17.9112 6.11724 18.7993C9.96592 19.4655 16.109 19.1694 17.0157 15.8203C16.9787 12.8783 15.0173 11.5831 12.6859 9.21464Z"
          fill={`url(#${svgId}_paint3_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M5.15503 6.7352C4.61843 10.6579 11.6497 21.1493 15.9424 19.8725C12.6674 26.4597 5.41408 15.9498 4.10034 12.1567C4.37789 10.1213 4.50741 9.12212 5.15503 6.7352Z"
          fill={`url(#${svgId}_paint4_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M9.2812 9.19614C9.42922 10.6579 10.0028 12.9893 9.2812 14.5251C8.55957 16.0609 5.26598 18.8734 6.17264 21.3343C8.28202 25.9231 13.0374 20.5202 13.0004 17.8187C13.2039 15.6353 11.7791 10.6579 12.6673 8.10444L12.2602 7.58635L9.74378 6.84622C9.33671 7.43832 9.18868 8.23397 9.2812 9.19614Z"
          fill={`url(#${svgId}_paint5_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.1878 1.99836C4.76638 5.0699 6.98677 9.88075 7.22732 15.0987L10.7614 4.84786C10.5024 3.79317 10.3359 3.09005 10.1878 1.99836Z"
          fill={`url(#${svgId}_paint6_linear_1574_1890)`}
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M10.743 4.82936C8.00452 6.99424 8.13404 10.6209 7.30139 14.8211C8.80016 10.8799 8.06003 7.75288 10.743 4.82936Z"
          fill="#532171"
          fillOpacity="0.316"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M6.57977 22.1484C8.06004 22.352 8.81867 22.5555 8.89268 22.7035C8.94819 23.4807 6.83882 22.9256 6.57977 22.1484Z"
          fill="white"
        />
        <path
          fillRule="evenodd"
          clipRule="evenodd"
          d="M7.31993 1.99836C6.0432 3.42311 4.65546 6.03207 3.95233 7.49383C3.7673 8.69655 3.85981 10.3988 4.06335 11.8421C4.22988 8.47451 5.4511 4.58882 7.31993 1.99836Z"
          fill="#EDEDED"
          fillOpacity="0.603"
        />
      </g>
      <defs>
        <linearGradient
          id={`${svgId}_paint0_linear_1574_1890`}
          x1="9.64139"
          y1="1.82975"
          x2="8.82142"
          y2="23.6443"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D8DC" />
          <stop offset="1" stopColor="white" stopOpacity="0.385" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint1_linear_1574_1890`}
          x1="9.31165"
          y1="1.48217"
          x2="0.744942"
          y2="20.2578"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D67AF" stopOpacity="0.672" />
          <stop offset="1" stopColor="#9F8DAF" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint2_linear_1574_1890`}
          x1="10.4952"
          y1="7.89989"
          x2="10.4952"
          y2="22.9799"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#633385" stopOpacity="0.762" />
          <stop offset="1" stopColor="#B7B4B4" stopOpacity="0.278" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint3_linear_1574_1890`}
          x1="4.89725"
          y1="8.3265"
          x2="8.75927"
          y2="18.5563"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91739F" stopOpacity="0.46" />
          <stop offset="1" stopColor="#32054F" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint4_linear_1574_1890`}
          x1="13.5573"
          y1="21.4753"
          x2="2.97211"
          y2="12.7081"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#806B87" stopOpacity="0.331" />
          <stop offset="1" stopColor="#340A50" stopOpacity="0.821" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint5_linear_1574_1890`}
          x1="10.9185"
          y1="7.74449"
          x2="3.47742"
          y2="19.0665"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#715383" stopOpacity="0.145" />
          <stop offset="1" stopColor="#F4F4F4" stopOpacity="0.234" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint6_linear_1574_1890`}
          x1="12.1172"
          y1="10.5263"
          x2="6.94458"
          y2="10.2106"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5A1A8" stopOpacity="0.356" />
          <stop offset="1" stopColor="#370C50" stopOpacity="0.582" />
        </linearGradient>
        <clipPath id={`${svgId}_clip0_1574_1890`}>
          <rect width="18" height="23.6842" fill="white" />
        </clipPath>
      </defs>
    </SvgIcon>
  )
}

export const ElixirLight: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const svgId = props.id ?? 'elixirlight'

  return (
    <SvgIcon {...props} viewBox="0 0 19 25">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57814 0.0976562C4.16798 2.71484 -2.62889 16.2891 3.03517 22.2461C8.26954 28.7891 19.7149 22.5 16.3945 14.3359C12.1953 7.30469 9.55861 6.93359 9.57814 0.0976562Z"
        fill={`url(#${svgId}_paint0_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.57812 0.0390625C4.1875 2.65625 -2.62891 16.1914 3.05469 22.168C5.14453 24.5898 8.52343 25.3906 10.418 23.9453C15.5742 20.2734 11.8633 13.5742 12.4492 8.61328C10.3008 6.62109 9.53906 2.20703 9.57812 0.0390625Z"
        fill={`url(#${svgId}_paint1_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.07418 0.839844C3.71871 3.94531 2.56637 15.4883 3.36715 19.2383C4.96871 28.6328 18.0546 24.7656 16.9609 16.0156C15.789 10.3125 7.42965 8.35937 8.07418 0.839844Z"
        fill={`url(#${svgId}_paint2_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M12.3907 9.72656C14.4219 12.3438 14.8711 14.1406 13.7188 15.1758C8.30863 18.9648 1.74613 16.6211 3.17192 7.89062C1.31645 12.3047 -2.12105 18.9062 5.45707 19.8438C9.51957 20.5469 16.0039 20.2344 16.961 16.6992C16.9219 13.5938 14.8516 12.2266 12.3907 9.72656Z"
        fill={`url(#${svgId}_paint3_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.44141 7.10938C3.875 11.25 11.2969 22.3242 15.8281 20.9766C12.3711 27.9297 4.71484 16.8359 3.32812 12.832C3.62109 10.6836 3.75781 9.62891 4.44141 7.10938Z"
        fill={`url(#${svgId}_paint4_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M8.79683 9.70703C8.95308 11.25 9.55855 13.7109 8.79683 15.332C8.03511 16.9531 4.55854 19.9219 5.51558 22.5195C7.74214 27.3633 12.7617 21.6602 12.7226 18.8086C12.9375 16.5039 11.4335 11.25 12.371 8.55469L11.9414 8.00781L9.28511 7.22656C8.85542 7.85156 8.69917 8.69141 8.79683 9.70703Z"
        fill={`url(#${svgId}_paint5_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M9.75386 2.10938C4.0312 5.35156 6.37495 10.4297 6.62886 15.9375L10.3593 5.11719C10.0859 4.00391 9.91011 3.26172 9.75386 2.10938Z"
        fill={`url(#${svgId}_paint6_linear_1581_2041)`}
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.3398 5.09766C7.44922 7.38281 7.58594 11.2109 6.70703 15.6445C8.28906 11.4844 7.50781 8.18359 10.3398 5.09766Z"
        fill="#330A4C"
        fillOpacity="0.316"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M5.94531 23.3789C7.50781 23.5937 8.30859 23.8086 8.38672 23.9648C8.44531 24.7852 6.21875 24.1992 5.94531 23.3789Z"
        fill="white"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M6.72662 2.10938C5.37896 3.61328 3.91412 6.36719 3.17193 7.91016C2.97662 9.17969 3.07427 10.9766 3.28912 12.5C3.4649 8.94531 4.75396 4.84375 6.72662 2.10938Z"
        fill="#EDEDED"
        fillOpacity="0.603"
      />
      <defs>
        <linearGradient
          id={`${svgId}_paint0_linear_1581_2041`}
          x1="9.17702"
          y1="1.93141"
          x2="8.31149"
          y2="24.9579"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#D9D8DC" />
          <stop offset="1" stopColor="white" stopOpacity="0.385" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint1_linear_1581_2041`}
          x1="8.82894"
          y1="1.56452"
          x2="-0.213695"
          y2="21.3832"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#8D67AF" stopOpacity="0.672" />
          <stop offset="1" stopColor="#9F8DAF" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint2_linear_1581_2041`}
          x1="10.0783"
          y1="8.33877"
          x2="10.0783"
          y2="24.2565"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#26053D" stopOpacity="0.762" />
          <stop offset="1" stopColor="#B7B4B4" stopOpacity="0.278" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint3_linear_1581_2041`}
          x1="4.16931"
          y1="8.78908"
          x2="8.24589"
          y2="19.5872"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#91739F" stopOpacity="0.46" />
          <stop offset="1" stopColor="#32054F" stopOpacity="0.54" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint4_linear_1581_2041`}
          x1="13.3105"
          y1="22.6684"
          x2="2.13721"
          y2="13.4141"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#463D49" stopOpacity="0.331" />
          <stop offset="1" stopColor="#340A50" stopOpacity="0.821" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint5_linear_1581_2041`}
          x1="10.525"
          y1="8.17474"
          x2="2.67062"
          y2="20.1258"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#715383" stopOpacity="0.145" />
          <stop offset="1" stopColor="#F4F4F4" stopOpacity="0.234" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint6_linear_1581_2041`}
          x1="11.7904"
          y1="11.1111"
          x2="6.33041"
          y2="10.7779"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#A5A1A8" stopOpacity="0.356" />
          <stop offset="1" stopColor="#370C50" stopOpacity="0.582" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
