import { Button, styled, Typography } from '@mui/material'
import trackEvent from 'packs/main/track_event'
import React, { FC, useCallback, useMemo } from 'react'

import { LanguageConfiguration } from '../../../../../utils/languages'
import LanguageIcon from '../../../Monaco/FilePane/LanguageIcon'
import { useLanguageInfo } from '../../LanguageInfoProvider'
import { isProjectTemplateVariation, SupportedLanguage } from './useSupportedLanguages'

const LanguageButton = styled(Button)(({ theme }) => ({
  flex: '1 1 auto',
  padding: '8px 12px',
  justifyContent: 'flex-start',
  color: 'inherit',
  backgroundColor: 'transparent',
  overflow: 'hidden',
  minWidth: 0,
  '&:hover': {
    backgroundColor: theme.palette.editor?.sidebar.hover,
  },
}))

const StyledLanguageIcon = styled(LanguageIcon)(() => ({
  maxHeight: 24,
  width: 24,
}))

const StyledInfoButton = styled(Button)(({ theme }) => ({
  color: theme.palette.text.secondary,
  textDecoration: 'none',
  marginRight: theme.spacing(1),
  minWidth: 'initial',
  minHeight: 'initial',
  padding: '2px 8px',
  background: 'rgba(255,255,255,0.1)',
  '&:hover': {
    backgroundColor: 'rgba(255,255,255,0.2)',
  },
}))

interface InfoButtonProps {
  language: LanguageConfiguration
}

const InfoButton: FC<React.PropsWithChildren<InfoButtonProps>> = ({ language }) => {
  const { setLanguage } = useLanguageInfo()

  const handleClick = useCallback(() => {
    setLanguage(language)
    trackEvent('Language Info Clicked', {
      language: language.id,
      from: 'list',
    })
  }, [language, setLanguage])

  return (
    <StyledInfoButton variant="text" onClick={handleClick}>
      Info
    </StyledInfoButton>
  )
}

const LanguageWrapper = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  breakInside: 'avoid-column',
  minWidth: 140,
  borderRadius: 4,
  backgroundColor: 'transparent',
  position: 'relative',
  '&:hover': {
    backgroundColor: theme.palette.editor?.sidebar.hover,
  },
  [`&:not(:hover) ${StyledInfoButton}`]: {
    display: 'none',
  },
  // Fixed height to avoid some column flow layout issues with oddly sized items.
  height: '40px',
  // Using padding instead of margin to avoid oddities with column layout. But split the padding
  // between top and bottom to keep the child buttons centered inside the area that gets
  // highlighted on hover.
  paddingBottom: theme.spacing(0.375),
  paddingTop: theme.spacing(0.375),
  // Also using content-box so that the padding's effect on the height is contistent between
  // dashboard/pad/onboarding.
  boxSizing: 'content-box',
}))

export const LangItem: FC<
  React.PropsWithChildren<{
    lang: SupportedLanguage
    onClick: (l: LanguageConfiguration) => void
  }>
> = ({ lang, onClick }) => {
  const langConfig = useMemo(() => {
    return (isProjectTemplateVariation(lang)
      ? {
          name: lang.slug,
          display: lang.name,
          type: 'project',
          id: lang.id,
        }
      : {
          name: lang.id,
          category: lang.category as LanguageConfiguration['category'],
          display: lang.name,
          version: lang.version ?? '',
          language_client_id: lang.languageClientId ?? '',
          type: 'language',
        }) as LanguageConfiguration
  }, [lang])
  return (
    <LanguageWrapper>
      <LanguageButton
        startIcon={
          <StyledLanguageIcon language={isProjectTemplateVariation(lang) ? lang.slug : lang.id} />
        }
        onClick={() => onClick(langConfig)}
      >
        <Typography noWrap fontSize="inherit">
          {lang.name}
        </Typography>
      </LanguageButton>
      <InfoButton language={langConfig} />
    </LanguageWrapper>
  )
}
