import { SvgIcon, SvgIconProps } from '@mui/material'
import React from 'react'

export const Kotlin: React.FC<React.PropsWithChildren<SvgIconProps>> = (props) => {
  const svgId = props.id ?? 'kotlin'
  return (
    <SvgIcon {...props} viewBox="0 0 18 18">
      <path d="M0 0H9.19688L0 9.05625V0Z" fill={`url(#${svgId}_paint0_linear_1574_1852)`} />
      <path d="M18 18L9.08437 8.80313L0 18H18Z" fill={`url(#${svgId}_paint1_linear_1574_1852)`} />
      <path d="M0 18L18 0H9.08437L0 8.95781V18Z" fill={`url(#${svgId}_paint2_linear_1574_1852)`} />
      <defs>
        <linearGradient
          id={`${svgId}_paint0_linear_1574_1852`}
          x1="-1.6733"
          y1="6.84759"
          x2="5.66705"
          y2="-1.17028"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C93C1" />
          <stop offset="0.163" stopColor="#2391C0" />
          <stop offset="0.404" stopColor="#378BBE" />
          <stop offset="0.696" stopColor="#587EB9" />
          <stop offset="0.995" stopColor="#7F6CB1" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint1_linear_1574_1852`}
          x1="6.12464"
          y1="20.9776"
          x2="13.4983"
          y2="13.3419"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#1C93C1" />
          <stop offset="0.216" stopColor="#2D8EBF" />
          <stop offset="0.64" stopColor="#587EB9" />
          <stop offset="0.995" stopColor="#7F6CB1" />
        </linearGradient>
        <linearGradient
          id={`${svgId}_paint2_linear_1574_1852`}
          x1="0.455625"
          y1="13.3944"
          x2="13.0051"
          y2="0.297562"
          gradientUnits="userSpaceOnUse"
        >
          <stop stopColor="#C757A7" />
          <stop offset="0.046" stopColor="#CA5A9E" />
          <stop offset="0.241" stopColor="#D66779" />
          <stop offset="0.428" stopColor="#E17357" />
          <stop offset="0.6" stopColor="#E97C3A" />
          <stop offset="0.756" stopColor="#EF8324" />
          <stop offset="0.888" stopColor="#F28817" />
          <stop offset="0.982" stopColor="#F48912" />
        </linearGradient>
      </defs>
    </SvgIcon>
  )
}
