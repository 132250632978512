import { Box, CircularProgress, Fade, Paper, styled, SxProps, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'

interface ILoadingOverlayProps {
  isLoading: boolean
  message?: string
  minDisplayMS?: number
  sx?: SxProps
}

const LoadingOverlay = styled(Paper)({
  width: '100%',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  position: 'absolute',
  height: 200,
  backgroundColor: 'inherit',
})

const LoadingOverlayMessage = styled(Typography)(({ theme }) => ({
  marginTop: theme.spacing(2),
}))

/**
 * Component to hide a block of content and show a spinner with optional message. This is intended to be used for
 * a page load where data needs to be fetched prior to showing the page's content. When loading, the child components
 * are hidden and the spinner/message are shown.
 *
 * This component uses some "hysteresis" to prevent the loading view from flashing and disappearing too quickly. By
 * default, the loading view will be shown for at least 700ms. This is configurable via the `minDisplayMS` prop.
 * Setting `minDisplayMS` to 0 will eliminate the hysteresis and the loading view will be hidden as soon as the
 * `isLoading` prop becomes false.
 *
 * Usage:
 * ```
 * const loadingBool = ...some condition or value here...
 *
 * return (
 *   <LoadingBlock isLoading={loadingBool} message="Wait while we get your stuff">
 *     <div>My content here</div>
 *   </LoadingBLock>
 * )
 * ```
 */
export const LoadingBlock: React.FC<React.PropsWithChildren<ILoadingOverlayProps>> = ({
  isLoading,
  message,
  minDisplayMS = 700,
  sx,
  children,
}) => {
  const mountedRef = useRef(false)
  const [inHysteresis, setInHysteresis] = useState(isLoading && !!minDisplayMS)

  const showProgress = isLoading || inHysteresis

  useEffect(() => {
    mountedRef.current = true
    return () => {
      mountedRef.current = false
    }
  }, [])

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout>
    if (minDisplayMS && isLoading) {
      setInHysteresis(true)
      timeout = setTimeout(() => {
        setInHysteresis(false)
      }, minDisplayMS)
    }
    return () => {
      // Only clear the state and timeout if we are unmounting.
      if (!mountedRef.current) {
        setInHysteresis(false)
        clearTimeout(timeout)
      }
    }
  }, [isLoading, minDisplayMS])

  return (
    <Box position="relative" height="100%" sx={sx}>
      <Fade in={showProgress}>
        <LoadingOverlay elevation={0} data-testid="loadingblock-loading">
          <CircularProgress />
          {message ? (
            <LoadingOverlayMessage variant="subtitle1">{message}</LoadingOverlayMessage>
          ) : null}
        </LoadingOverlay>
      </Fade>
      <Fade in={!showProgress}>
        <Box data-testid="loadingblock-content" height="100%">
          {children}
        </Box>
      </Fade>
    </Box>
  )
}
